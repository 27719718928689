import styles from "./home2.module.scss";
import { TypeAnimation } from "react-type-animation";
import s1Icon from "../icons/s1.svg";
import s2Icon from "../icons/s2.svg";
import s3Icon from "../icons/s3.svg";
import s4Icon from "../icons/s4.svg";
import BlurAnimate from "../company/animations/BlurToVisible";
import "./animation.css";

export default function HomeV2() {
  return (
    <div className={styles.layout}>
      <header className={styles.home__header}>
        <img
          onClick={() => {
            window.location.href = "/";
          }}
          src="/logoname.png"
        />
      </header>
      <main className={styles.main}>
        <div className={styles.main__title}>Welcome to the Era of </div>
        <div className={styles.main__subtitle}>
          Actionable{" "}
          <TypeAnimation
            sequence={["Influence", 1000]}
            wrapper="span"
            speed={5}
            style={{}}
            repeat={Infinity}
          />
        </div>
        <div className={styles.main__desc}>
          Fueled by AI, we empower businesses to convey their best messaging and
          help customers gain insights through AI-driven social wisdom. Our
          personalized, rapid content at scale transforms decision-making,
          enabling quick, informed choices.
        </div>
        <div className={styles.main__scroll}>
          {/* <img src="/scroll.gif" /> */}
          <div className={styles.mouse}></div>
        </div>
      </main>

      <BlurAnimate className={styles.services}>
        <div className={styles.title}>
          Your Business, Our Expertise in Action
        </div>
        <div className={styles.subtitle}>
          Delivering Solutions to Achieve{" "}
          <strong>Unprecedented Customer Conversion</strong>
        </div>
        <div className={styles.services__list}>
          <div className={styles.service}>
            <div className={styles.service__head}>
              <img src={s1Icon} />
              <span>Empowering businesses with B2B2C solutions</span>
            </div>
            <div className={styles.service__content}>
              Our <strong>B2B2C solution</strong> streamlines communication,
              accelerating the journey from awareness to decision with a single,
              configurable platform that boosts{" "}
              <strong>customer conversion.</strong>
            </div>
          </div>
          <div className={styles.service}>
            <div className={styles.service__head}>
              <img src={s2Icon} />
              <span>Interactive Video Chatbots for Engaging Support</span>
            </div>
            <div className={styles.service__content}>
              Enhance engagement with <strong>AI-driven chatbots</strong> that
              integrate video for real-time, personalized responses, creating a
              more
              <strong> immersive user experience.</strong>
            </div>
          </div>
          <div className={styles.service}>
            <div className={styles.service__head}>
              <img src={s3Icon} />
              <span>AI-Powered Video Summaries with Social Wisdom</span>
            </div>
            <div className={styles.service__content}>
              Leverage AI to deliver concise <strong>video summaries</strong>{" "}
              enriched with
              <strong> social media insights,</strong> keeping customers
              informed and saving time for smarter decisions in a fast-paced
              <strong> digital world.</strong>
            </div>
          </div>
          <div className={styles.service}>
            <div className={styles.service__head}>
              <img src={s4Icon} />
              <span>Uncovering Hidden Insights with Data-Driven AI</span>
            </div>
            <div className={styles.service__content}>
              Our AI-driven approach analyzes <strong>customer behavior</strong>{" "}
              to identify pain points and inefficiencies, delivering
              hyper-personalized follow-ups and{" "}
              <strong>actionable strategies.</strong>
            </div>
          </div>
        </div>
      </BlurAnimate>
      <BlurAnimate className={styles.products}>
        <div className={styles.title}>Tailored Solutions</div>
        <div className={styles.subtitle}>
          Innovative answers for your business challenges.
        </div>
        <div className={styles.product}>
          <img
            className={styles.product__img}
            fetchPriority="low"
            src="/p-11.gif"
          />
          <div className={styles.product__detail}>
            <div className={styles.product__detail__title}>
              AI-Powered Social Wisdom: 
              <strong>Compiled Video Summaries</strong>
            </div>
            <div className={styles.product__detail__desc}>
              AI-Powered Social Wisdom: Harness AI-driven Social Wisdom to
              deliver video summarise that capture key insights from social
              media trends providing customers with concise and relevant
              content, saving time and enabling smarter decisions.
            </div>
          </div>
        </div>
        <div className={`${styles.product} ${styles.product__reverse}`}>
          <img
            className={styles.product__img}
            fetchPriority="low"
            src="/p-12.gif"
          />
          <div
            className={`${styles.product__detail} ${styles.product__detail__reverse}`}
          >
            <div className={styles.product__detail__title}>
              Interactive Chatbots with{" "}
              <strong> videos to answer the question</strong>
            </div>
            <div className={styles.product__detail__desc}>
              Interactive Chatbots with videos to answer the question: Our
              interactive chatbots offer effective and personalized
              interactions. Customers are far more likely to be convinced by
              well-crafted videos available within the chatbot, offering clear
              and effective messeging that elevate the overall user experience.
            </div>
          </div>
        </div>
        <div className={styles.product}>
          <img
            className={styles.product__img}
            fetchPriority="low"
            src="/p-3.gif"
          />
          <div className={styles.product__detail}>
            <div className={styles.product__detail__title}>
              Interactive Referral System for
              <strong>  Building Trust</strong>
            </div>
            <div className={styles.product__detail__desc}>
              Interactive Referral System for Building Trust: Our AI-powered
              solution includes an interactive referral system that taps into
              trusted connections, boosting conversion rates by helping
              customers find deals within their network.
            </div>
          </div>
        </div>
        <div className={`${styles.product} ${styles.product__reverse}`}>
          <img
            className={styles.product__img}
            fetchPriority="low"
            src="/p-14.gif"
          />
          <div
            className={`${styles.product__detail} ${styles.product__detail__reverse}`}
          >
            <div className={styles.product__detail__title}>
              Uncovering Hidden Insights with <strong>AI-Powered Data</strong>
            </div>
            <div className={styles.product__detail__desc}>
              Uncovering Hidden Insights with AI-Powered Data: In today’s
              complex business landscape, our AI-driven approach identifies
              customer pain points, uncovers inefficiencies, and extracts
              meaningful insights. By analyzing customer behavior, we deliver
              targeted, hyper-personalized follow-ups that transform insights
              into actionable strategies.
            </div>
          </div>
        </div>
      </BlurAnimate>
      <section className={styles.footer}>
        <div className={styles.footer__head}>
          <img src="/logo192.png" />
          <p>Transforming Business with AI-Powered Growth and Engagement</p>
          <div></div>
        </div>
        <div className={styles.footer__content}>
          <div className={styles.footer__content__links}>
            <div className={styles.footer__content__links__title}>Company</div>
            <div
              onClick={() => {
                window.location.href = "/career";
              }}
            >
              Careers
            </div>
            <div
              onClick={() => {
                window.location.href = "/terms";
              }}
            >
              Terms & Conditions
            </div>
            <div
              onClick={() => {
                window.location.href = "/privacy";
              }}
            >
              Privacy Policy
            </div>
            <div
              onClick={() => {
                window.location.href = "/investors";
              }}
            >
              Investors
            </div>
            <div
              onClick={() => {
                window.location.href = "mailto:contact@sowiz.live";
              }}
            >
              Contact Us
            </div>
          </div>
          <div className={styles.footer__content__links}>
            <div className={styles.footer__content__links__title}>About Us</div>
            <div
              onClick={() => {
                window.location.href = "/investors";
              }}
            >
              Investors
            </div>
            <div
              onClick={() => {
                window.location.href = "mailto:contact@sowiz.live";
              }}
            >
              Contact Us
            </div>
          </div>
          <div className={styles.footer__content__copy}>
            © 2024 All rights reserved
          </div>
        </div>
      </section>
    </div>
  );
}
